import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';
import { useNavigate } from "react-router-dom";

export default function ProductCard(props: any) {

    const {
        name, 
        price, 
        image, 
        id,
        item
    } = props;

    const navigate = useNavigate();

    const encodedName = encodeURIComponent(name);

    return (
        <div 
            className="product_card" 
            onClick={() => {
                navigate("/product/detail", { state: { item } });
            }}
        >
            <img src={image}/>

            <div className="product_card_info">
            <div className="product_card_info_left">
                <p className="product_card_name">{decodeURIComponent(encodedName)}</p>
                <p className="product_card_price">${price}</p>
            </div>
            <div className="product_card_info_right">
                <AddShoppingCartOutlinedIcon />
            </div>
            </div>

            
        </div>
    )
}