import { Link } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { ApiRequest } from "GlobalFunctions";
import { useContext, useEffect, useState } from "react";
import SdkclaroContext from "Context/SdkclaroContext";

export default function Banner(props:any) {
    const { onResourceLoad } = props;
    const { sdkclaroInstance, idOpen, username } = useContext(SdkclaroContext);

    const openURL = (url: string) => {
        sdkclaroInstance.getTopic("EXECUTE_Minimal_View", {
            typeOperation: "openUrl",
            parameters: url
          });
    }

    const [data, setData] = useState<any>([]);
    const loadData = () => {
        const state_id = localStorage.getItem('state');

        ApiRequest({
            url: `/v2/estados/${state_id}/banners`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            setResponse: (response: any) => {
                setData(response);
            }
        });
    }

    useEffect(() => {
        loadData();
    }, []);


    return (
        <Carousel 
            className='promotional_carousel'
            showThumbs={false} 
            showStatus={false} 
            infiniteLoop={true} autoPlay={true} 
            interval={5000} 
            showArrows={false} 
            showIndicators={false}
        >
            {
                data.map((item: any, index:any) => (
                <div key={index} onClick={() => openURL(item.link)}>
                    <img
                        src={item.img}
                        alt="banner promocional"
                        onLoad={onResourceLoad}
                        onError={onResourceLoad}
                    />
                </div>
                ))
            }

        </Carousel>
    );
}