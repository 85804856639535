import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import axios from 'axios';
import { ProjectConfig } from 'Global';
import { ApiRequest } from 'GlobalFunctions';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SdkclaroContext from 'Context/SdkclaroContext';

export default function OrdersList(props: any) {
	const navigate = useNavigate();
	const { sdkclaroInstance, idOpen, profileInformation } =
		useContext(SdkclaroContext);

	const [data, setData] = useState<any>([]);

	const loadData = () => {
		console.log('USERNAME', profileInformation.username);
		let body = new FormData();
		body.append('username', profileInformation.username);

		ApiRequest({
			url: '/v2/historial',
			method: 'post',
			formData: true,
			body: body,
			setResponse: (response: any) => {
				setData(response);
				console.log('ORDER LIST', response);
			},
		});
	};

	useEffect(() => {
		loadData();
	}, []);

	return (
		<div className="content">
			{data && data.length > 0 ? (
				<>
					{data.map((product: any, i: any) => {
						let time = '';
						let date = '';

						try {
							const fullDate = new Date(product.hora);
							time = fullDate.toLocaleTimeString();
							date = fullDate.toLocaleDateString(); //c
						} catch (e) {
							console.log(e);
						}

						return (
							<div
								key={i}
								className="history_card"
								onClick={() => {
									navigate('/orders/status', {
										state: {
											id: product.id,
										},
									});
								}}
							>
								<LocalMallOutlinedIcon style={{ color: '#CBCBCB' }} />
								<div className="history_card_info">
									<p className="history_card_name">
										{decodeURIComponent(escape(product.producto.nombre))}
										<span className="history_price">
											${product.costo.toFixed(2)}
										</span>
									</p>
									<p className="history_card_date">
										<span className="history_date">{date} </span>
										<span className="history_time">{time}</span>
									</p>
								</div>
							</div>
						);
					})}
				</>
			) : (
				<div className="empty_message">No cuentas con compras</div>
			)}
		</div>
	);
}
